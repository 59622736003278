import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  background-color: #eee;
`
const NotFoundContainer = () => {
  return <Container> not found</Container>
}

export default NotFoundContainer
